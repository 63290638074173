/********************** Media 425px width ***************/
@media only screen and (max-width: 425px) {
  #topline-press-fix {
    padding-left: 57%;
  }
}

@media only screen and (max-width: 375px) {
  #topline-press-fix {
    padding-left: 49.5%;
  }
}

@media only screen and (max-width: 320px) {
  #topline-press-fix {
    padding-left: 43%;
  }
}
