/* .press-column-wrapper{
    position: relative;
  
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
   
  margin-left: 20%;
  margin-right: 20%;

} */

/* .press-column{
    flex-grow: 1;
	width: 33%;
	height: 100px;
    color:white;
   
} */

.press {
  display: flex;
  flex-direction: column;
  line-height: 50px;
  align-items: center;
}

#testpress {
  background-color: white;
  color: red;
  background-color: white;
  color: red;
}

#testscroll {
  background-color: white;
  color: red;
}

.contented {
  height: 100vh;
}

.test-a {
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.test-a:hover {
  opacity: 50%;
  color: red;
  transition-duration: 0.3s;
  font-size: 18px;
}

/**************************** media 1024px *********************/
@media only screen and (max-width: 1024px) {
  .press {
    display: flex;
    flex-direction: column;
    line-height: 70px;
    align-items: center;

    margin-left: 40px;
    margin-right: 40px;
  }
}
/************************* media 768 px ******************/
@media only screen and (max-width: 768px) {
  .press {
    display: flex;
    flex-direction: column;
    line-height: 60px;
    align-items: center;
    margin-left: 40px;
    margin-right: 40px;
  }

  li {
    line-height: 30px;
  }
}
/************************* media 425 px ******************/
@media only screen and (max-width: 425px) {
  #press-title {
    text-align: center;
    font-size: 30px;
    padding-left: 29px;
  }
}
