/********************** Media 425px width ***************/
@media only screen and (max-width: 425px) {
  #topline-work-fix {
    padding-left: 57%;
  }
}

@media only screen and (max-width: 379px) {
  #red-line-video-fix {
    margin-left: 55px;
  }
}

@media only screen and (max-width: 375px) {
  #topline-work-fix {
    padding-left: 49.5%;
  }
  #red-line-video-fix {
    margin-left: 76px;
  }
}

@media only screen and (max-width: 320px) {
  #topline-work-fix {
    padding-left: 43%;
  }
}
