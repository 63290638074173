.cv-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#cv-button {
  width: 180px;
  height: 60px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #91c9ff;
  outline: none;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
}

#cv-button:hover {
  transition: 1s ease-in-out;
  /* background: #4F95DA; */
  background: rgb(152, 19, 19);
  border-top-right-radius: 40px;
  border-bottom-left-radius: 40px;
}
