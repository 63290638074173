.contact-wrap {
  display: flex;
  flex-direction: column;
  padding-top: 90px;
  align-items: left;
  margin: auto;
  width: 50%;
  padding-left: 300px;
  padding-top: 190px;
}

h6 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-weight: lighter;
}
.contact-header {
  font-size: 30px;
  text-align: left;
  color: rgb(249, 249, 249);
  cursor: default;
}
.contact-header-sub {
  font-size: 20px;
  text-align: left;
  font-weight: lighter;
  color: rgb(249, 249, 249);
  cursor: default;
}
a {
  text-decoration: underline;
  color: white;
}

.contact-p {
  padding-bottom: 80px;
  font-size: 15px;
  border: none;
  text-align: left;
}

@media only screen and (max-width: 320px) {
  .contact-wrap {
    padding-left: 0px;
    padding-top: 190px;
  }

  .contact-header-sub {
    font-size: 5px;
    white-space: nowrap;
  }
}
@media only screen and (max-width: 375px) {
  .contact-wrap {
    padding-left: 0px;
    padding-top: 190px;
  }

  .contact-header-sub {
    font-size: 5px;
    white-space: nowrap;
  }
}
@media only screen and (max-width: 425px) {
  .contact-wrap {
    padding-left: 0px;
    padding-top: 190px;
  }

  .contact-header-sub {
    font-size: 15px;
  }
}
@media only screen and (max-width: 768px) {
  .contact-wrap {
    padding-left: 0px;
    padding-top: 190px;
  }
}
@media only screen and (max-width: 1024px) {
  .contact-wrap {
    padding-left: 0px;
    padding-top: 190px;
  }
}
