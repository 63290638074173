@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;
  background-color: #000000;
}
#steve-bio {
  text-transform: uppercase;
}
.center {
  text-align: center;
  padding-bottom: 30px;
}

.bio-header {
  justify-content: center;
}
p {
  display: flex;
  text-align: left;
  justify-content: center;
  background-color: #000000;
  color: white;
  margin: 0 auto;
  width: 50%;
  line-height: 2em;
  padding: 60px;
  cursor: default;
}

.top-line {
  border: 1px solid red;
  margin-right: 30%;
  margin-left: 30%;
  margin-bottom: 30px;
  margin-top: 15px;
}

.bottom-line {
  border: 1px solid red;
  margin-right: 30%;
  margin-left: 30%;
  margin-top: 1em;
  margin-bottom: 6em;
}

.subnav {
  display: flex;
}

.bio-wrapper {
  display: flex;
  flex-direction: column;
}

#steve-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
  height: 300px;
}
/************************* media 1024 px *****************************/

@media only screen and (max-width: 1024px) {
  p {
    width: 80%;
  }
}
/************************* media 768 px *****************************/

@media only screen and (max-width: 768px) {
  p {
    width: 80%;
  }
}
/************************* media 425 px *****************************/

@media only screen and (max-width: 425px) {
  #topline-bio-fix {
    margin-left: 108px;
  }
  .top-line {
    border: 1px solid red;

    margin-left: 20%;
    width: 200px;
  }
  #steve-image {
    margin-top: 40px;
    width: 300px;
    height: 230px;
  }

  p {
    font-size: 14px;
    width: 400px;
    text-align: left;
  }

  #steve-bio {
    padding-left: 25px;
  }

  #steve-bio-sub {
    padding-left: 25px;
  }
}
/************************* media 379 px *****************************/

@media only screen and (max-width: 379px) {
  .top-line {
    border: 1px solid red;
    margin-left: 80px;
    max-width: 210px;
  }
  #bio-paragraph {
    padding-right: 85px;
    padding-top: 10px;
  }
}

/************************* media 375 px *****************************/

@media only screen and (max-width: 375px) {
  .top-line {
    border: 1px solid red;

    margin-left: 24%;
    width: 150px;
  }
}

/************************* media 320 px *****************************/

@media only screen and (max-width: 320px) {
  .top-line {
    border: 1px solid red;
    margin-left: 27%;
    max-width: 100px;
  }

  #bio-paragraph {
    font-size: smaller;
    padding-right: 140px;
    padding-top: 10px;
  }
}
